
import { ethers } from "ethers";
import { JsonRpcProvider } from "ethers/providers";
import { Vue, Component } from "vue-property-decorator";
import Base from "./Base";
import MixinMenu from "./_mixin_menu";

import Balance from "../components/balance.vue";
import Big from "bignumber.js";
import { getContract, getProvider } from "../lib/blockchain-helper";
import QR from "qrcode";
import { BigNumber } from "ethers/utils";

type Coin = {
    id: string;
    name: string;
    tokenAddress: string;
    blockchain: "Thundercore" | "Binance";
    dp: number;
    displayDp: number;
    withdrawNetworkFee?: string;
};

const network = "thunder_main";

interface Receipt {
    id: Big;
    symbol: string;
    at: Date;
    amount: Big;
    transferTarget: string;
    memo: string;
    hash?: string;
}

function toBytes8(str: string) {
    const bytes = ethers.utils.toUtf8Bytes(str);
    const arr: string[] = [];
    bytes.forEach((v) => arr.push(new Big(v).toString(16)));
    const hex = arr.join("");
    return "0x" + hex.padEnd(16, "0");
}

@Component({
    mixins: [MixinMenu],
    components: { Balance },
})
export default class Wallet extends Base {
    provider = new JsonRpcProvider("https://mainnet-rpc.thundercore.com");

    loading = false;

    selectedCoin: Coin | null = null;

    transfer = {
        target: "",
        amount: "",
    };

    gatewayBNBAddress = "bnb18r7xx73x33mf238qusauuaupu4s89a3jr5ay7a";

    search = "";

    proceed = null;

    coins: Coin[] = [
        {
            id: "TT",
            name: "ThunderToken",
            tokenAddress: "TT",
            blockchain: "Thundercore",
            dp: 18,
            displayDp: 4,
        },
        // {
        //     id: "EGG(OLD)",
        //     name: "The Egg (OLD)",
        //     tokenAddress: "0x013aBb84ca2d12ef0C4D0bEB7CF6b6b807962df5",
        //     blockchain: "Thundercore",
        //     dp: 18,
        //     displayDp: 6,
        // },
        {
            id: "EGG",
            name: "The Egg",
            tokenAddress: "0x8eD8Ae1703ACb33031cBD464400b57978Fe501aB",
            blockchain: "Thundercore",
            dp: 18,
            displayDp: 6,
        },
        {
            id: "TT-USDT",
            name: "TT-USDT",
            tokenAddress: "0x4f3C8E20942461e2c3Bdd8311AC57B0c222f2b82",
            blockchain: "Thundercore",
            dp: 6,
            displayDp: 2,
        },
        {
            id: "TT-WBTC",
            name: "TT-WBTC",
            tokenAddress: "0x18fB0A62f207A2a082cA60aA78F47a1af4985190",
            blockchain: "Thundercore",
            dp: 8,
            displayDp: 8,
        },
        {
            id: "TT-ETH",
            name: "TT-ETH",
            tokenAddress: "0x6576Bb918709906DcbFDCeae4bB1e6df7C8a1077",
            blockchain: "Thundercore",
            dp: 18,
            displayDp: 5,
        },
        {
            id: "TT-USDC",
            name: "TT-USDC",
            tokenAddress: "0x22e89898A04eaf43379BeB70bf4E38b1faf8A31e",
            blockchain: "Thundercore",
            dp: 6,
            displayDp: 2,
        },
        {
            id: "SET",
            name: "Seriously",
            tokenAddress: "0xbb9a3CC287a4060B06257435906E0491305C112C",
            blockchain: "Thundercore",
            dp: 18,
            displayDp: 6,
        },
        {
            id: "NICER",
            name: "Nicer",
            tokenAddress: "0x1F489E0282cFA883A4224C91309bC4D4c062ed93",
            blockchain: "Thundercore",
            dp: 18,
            displayDp: 6,
        },
        {
            id: "RISER",
            name: "RISER",
            tokenAddress: "0xC1E7000f379f2247Ae930ba98d5568cd9D0b924b",
            blockchain: "Thundercore",
            dp: 18,
            displayDp: 6,
        },
        {
            id: "FRSK",
            name: "Firestack",
            tokenAddress: "0x2a7a59c82812a4b70Dedf76ef4F3b7451A212a3f",
            blockchain: "Thundercore",
            dp: 18,
            displayDp: 8,
        },
        {
            id: "CARE",
            name: "Careful V4",
            tokenAddress: "0x62839205805a96F8b4122d4eCD028999E79FBd27",
            blockchain: "Thundercore",
            dp: 18,
            displayDp: 6,
        },
        {
            id: "RISK",
            name: "Serious Risk",
            tokenAddress: "0x137222fF76855E3804d7C2B345B2fB1d9d7591Ef",
            blockchain: "Thundercore",
            dp: 18,
            displayDp: 8,
        },
        {
            id: "BNB",
            name: "Binance Native Token",
            tokenAddress: "0x64D8723FFA3B145BAdd7a6896C966fD11CD8c7F1",
            blockchain: "Binance",
            dp: 8,
            displayDp: 8,
            withdrawNetworkFee: "0.001",
        },
        {
            id: "IDRTB",
            name: "Bep2-IDRTB",
            tokenAddress: "0xB162CfBbc4821FB33Fa83e3050240dD920dDa230",
            blockchain: "Binance",
            dp: 8,
            displayDp: 2,
            withdrawNetworkFee: "6000",
        },
        {
            id: "BUSD",
            name: "Bep2-BUSD",
            tokenAddress: "0x2e6b163D5bA678515A6a0D27e063eA794d79d975",
            blockchain: "Binance",
            dp: 8,
            displayDp: 2,
            withdrawNetworkFee: "0.45",
        },
        {
            id: "BTC-B",
            name: "Bep2-BTC",
            tokenAddress: "0x8a8A8568C27B29550fF5f877016662EFD47828C8",
            blockchain: "Binance",
            dp: 8,
            displayDp: 8,
            withdrawNetworkFee: "0.000008",
        },
        {
            id: "ETH-B",
            name: "Bep2-ETH",
            tokenAddress: "0x7DA201EC21E2cD2B79B36C16C0CBAC131504c1FC",
            blockchain: "Binance",
            dp: 8,
            displayDp: 8,
            withdrawNetworkFee: "0.0002",
        },
        {
            id: "TRX",
            name: "Bep2-TRX",
            tokenAddress: "0x0d246F3a3adF106ee8e204f28F2d340D86bF4Db0",
            blockchain: "Binance",
            dp: 8,
            displayDp: 8,
            withdrawNetworkFee: "3.5",
        },
        {
            id: "DOT",
            name: "Bep2-DOT",
            tokenAddress: "0xaEaCF8C931b45987506Ade0D19d41ea822e19A76",
            blockchain: "Binance",
            dp: 8,
            displayDp: 8,
            withdrawNetworkFee: "0.01",
        },
        // {
        //     id: "BIDR",
        //     name: "Bep2-BIDR",
        //     tokenAddress: "0xBdb50b9D1824F19F5725913C546D495290ea1894",
        //     blockchain: "Thundercore",
        //     dp: 8,
        //     displayDp: 8,
        // },
    ];

    qr = "";
    qrText = "";
    qrTitle = "";

    filteredCoins() {
        if (this.search) {
            return this.coins.filter(
                (v) =>
                    v.id.toLowerCase().includes(this.search.toLowerCase()) ||
                    v.name.toLowerCase().includes(this.search.toLowerCase())
            );
        }

        return this.coins;
    }

    gatewayDeposit = {
        linkId: "",
    };

    withdraw = {
        amount: "",
        target: "",
        memo: "",
    };

    toBig(v: any) {
        return new Big(v.toString());
    }

    startWithdraw(coin: Coin) {
        this.selectedCoin = coin;
        this.withdraw = {
            amount: "",
            target: "",
            memo: "",
        };

        this.$nextTick(function () {
            const win = window as any;
            win.jQuery("#withdraw-coin").modal("show");
        });
    }

    doWithdraw() {
        this.loading = true;
        this.$store.commit("blockchainProgress", "start withdraw");
        this.$nextTick(async function () {
            try {
                if (!this.selectedCoin) throw "no coin";

                if (this.withdraw.target.indexOf("bnb") != 0)
                    throw "Invalid address, please enter a valid binance chain address";

                const p = this.getProvider(false);
                if (!p) return;

                const provider = p;
                const signer = provider.getSigner();

                const amount = new Big(this.withdraw.amount)
                    .times(`1e${this.selectedCoin.dp}`)
                    .dp(0, Big.ROUND_DOWN);

                const my = getContract("", "GATEWAY", signer);

                const myErc = getContract(
                    "",
                    "ERC20",
                    signer,
                    this.selectedCoin.tokenAddress
                );

                const allowance: BigNumber = await myErc.allowance(
                    this.$store.state.account,
                    my.address
                );

                if (allowance.lt(amount.toString())) {
                    this.$store.commit(
                        "blockchainProgress",
                        `waiting for ${this.selectedCoin.id} spending approval`
                    );

                    const t = await myErc.approve(
                        my.address,
                        amount.times(99e12).toString()
                    );

                    await t.wait(1);
                }

                this.$store.commit(
                    "blockchainProgress",
                    `[approved] waiting confirmation`
                );

                const t = await my.withdraw(
                    toBytes8(this.selectedCoin.id),
                    amount.toString(),
                    this.withdraw.target,
                    this.withdraw.memo
                );

                window.swal(
                    "info",
                    `${this.withdraw.amount} ${this.selectedCoin.id} withdrawal received by gateway, please wait..`
                );

                const win = window as any;
                win.jQuery("#withdraw-coin").modal("hide");
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else msg = error.message || error.toString();
                alert(msg);
            } finally {
                this.$store.commit("blockchainProgress", "");
                this.loading = false;
            }
        });
    }

    startBurn(coin: Coin) {
        if (
            confirm(
                `You are about to BURN ${coin.id}.\nBurning means you are removing it from your balance and reduce the total supply of the network, are you sure?`
            )
        ) {
            const amount = prompt("How many coin would you like to burn?", "0");

            if (amount) {
                this.loading = true;

                this.$nextTick(async function () {
                    try {
                        const a = new Big(amount)
                            .times(`1e${coin.dp}`)
                            .dp(0, Big.ROUND_DOWN);
                        if (a.lte(0) || a.isNaN() || !a.isFinite())
                            throw "invalid amount";

                        const p = this.getProvider(false);
                        if (!p) return;

                        const provider = p;
                        const signer = p.getSigner();
                        await signer.getBalance();

                        const erc20 = getContract(network, "EGG", signer);
                        await erc20.burn(a.toString());
                    } catch (error) {
                        let msg;
                        if (error.data && error.data.message)
                            msg = error.data.message;
                        else msg = error.message || error.toString();
                        alert(msg);
                    } finally {
                        this.loading = false;
                    }
                });
            }
        }
    }

    startDeposit(coin: Coin) {
        this.selectedCoin = coin;
        this.qr = "";
        this.loading = true;

        this.$nextTick(async function () {
            try {
                const p = this.getProvider(false);
                if (!p) return;
                const provider = p;
                const innerGateway = getContract("", "GATEWAY", provider);

                this.gatewayDeposit.linkId = await innerGateway.links(
                    this.$store.state.account
                );

                this.$nextTick(function () {
                    const win = window as any;
                    win.jQuery("#deposit-coin").modal("show");
                });
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else msg = error.message || error.toString();
                alert(msg);
            } finally {
                this.loading = false;
            }
        });
    }

    async doLink() {
        this.loading = true;
        this.$nextTick(async function () {
            try {
                const p = this.getProvider(false);
                if (!p) return;

                const provider = p;
                const innerGateway = getContract(
                    "",
                    "GATEWAY",
                    provider.getSigner()
                );

                const t = await innerGateway.link();
                await t.wait(1);

                this.gatewayDeposit.linkId = await innerGateway.links(
                    this.$store.state.account
                );

                this.$nextTick(function () {
                    const win = window as any;
                    win.jQuery("#deposit-coin").modal("show");
                });
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else msg = error.message || error.toString();
                alert(msg);
            } finally {
                this.loading = false;
            }
        });
    }

    copy(text: string, title: string) {
        const input = this.$refs.copier as HTMLInputElement;
        input.value = text;
        console.log(input.value);
        input.focus();
        input.select();
        const r = document.execCommand("copy");
        console.log(r);
        alert(title + ", coppied to clipboard");
    }

    showQR(title: string, text: string) {
        this.qr = "";
        this.qrText = text;
        this.qrTitle = title;

        this.$nextTick(function () {
            const $ = (window as any)["jQuery"];
            $("#qr-code").modal("show");
            QR.toDataURL(text, (err, url) => {
                if (err) return alert(err.message);
                this.qr = url;
            });
        });
    }

    startTransfer(coin: Coin) {
        this.selectedCoin = coin;
        this.transfer.target = "";
        this.transfer.amount = "";
        this.$nextTick(function () {
            const win = window as any;
            win.jQuery("#transfer-coin").modal("show");
        });
    }

    doTransfer() {
        this.loading = true;
        this.$store.commit("blockchainProgress", "transfering token");

        this.$nextTick(async function () {
            try {
                if (!this.selectedCoin) throw "no coin";

                const p = this.getProvider(false);
                if (!p) return;
                const provider = p;
                const signer = provider.getSigner();

                const address = ethers.utils.getAddress(this.transfer.target);
                const amountEther = new Big(this.transfer.amount)
                    .times(`1e${this.selectedCoin.dp}`)
                    .dp(0, Big.ROUND_DOWN)
                    .toString();

                if (this.selectedCoin.id == "TT") {
                    const t = await signer.sendTransaction({
                        value: ethers.utils.bigNumberify(
                            amountEther.toString()
                        ),
                        to: address,
                    });
                    const r = await t.wait(1);
                    if (r.status) {
                        window.swal(
                            "info",
                            `transfered ${this.transfer.amount} ${this.selectedCoin.id} to ${address}`
                        );
                    }
                } else {
                    const erc20 = getContract(
                        "",
                        "ERC20",
                        signer,
                        this.selectedCoin.tokenAddress
                    );
                    const t = await erc20.transfer(address, amountEther);
                    const r = await t.wait(1);
                    if (r.status) {
                        window.swal(
                            "info",
                            `transfered ${this.transfer.amount} ${this.selectedCoin.id} to ${address}`
                        );
                    }
                }

                const win = window as any;
                win.jQuery("#transfer-coin").modal("hide");
            } catch (error) {
                let msg;
                if (error.data && error.data.message) msg = error.data.message;
                else msg = error.message || error.toString();
                alert(msg);
            } finally {
                this.$store.commit("blockchainProgress", "");
                this.loading = false;
            }
        });
    }
}
